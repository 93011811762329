export default class List {
  constructor() {
    this.items = [];
  }

  size(): number {
    return this.items.length;
  }

  add(value): void {
    this.items.push(value);
  }

  get(index: number) {
    return this.items[index];
  }

  toArray() {
    return this.items;
  }
}
