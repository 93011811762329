import List from "./list";
import User from "../models/user";
import { excuseApi } from "../commons/http";

export default class Users extends List {
    async fetch() {
        const res = await excuseApi({
            path: '/users',
            method: 'GET',
        });
        console.log(res);
        if (res.status === 200) {
            this.mapData(res.data.data);
        }
    }

    mapData(data = []) {
        for (const item of data){
            const user = new User();
            user.mapData(item);
            this.add(user);
        }
    }
}