import { excuseApi } from "../commons/http";

class User {
  constructor(data = {}) {
    this.id = data.id;
    this.name = data.name;
    this.address = data.address;
    this.phone_number = data.phone_number;
    this.email = data.email;
    this.referral_source = data.referral_source;
    this.tags = data.tags;
    this.created_at = data.created_at;
    this.updated_at = data.updated_at;
  }

  mapData(data) {
    this.id = data.id;
    this.name = data.name;
    this.address = data.address;
    this.phone_number = data.phone_number;
    this.email = data.email;
    this.referral_source = data.referral_source;
    this.tags = data.tags;
    this.created_at = data.created_at;
    this.updated_at = data.updated_at;
  }

  async save() {
    const res = await excuseApi({
      path: '/users',
      method: 'POST',
      body: {
        name: this.name,
        phone_number: this.phone_number,
        address: this.address,
        email: this.email,
        referral_source: this.referral_source,
        tags: this.tags,
      }
    });
    if (res.status === 200) {
      return res.data.data.data;
    }
  }
}

export default User;
