import Link from '@mui/material/Link';
import { useNavigate } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import Groups2Icon from '@mui/icons-material/Groups2';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';

const drawerWidth = 250;

const ResponsiveDrawer = (props) => {
  const navigate = useNavigate();

  const logOut = () => {
    localStorage.setItem('token', '');
    props.setLoggedIn(false);
    navigate('/login');
  }

  const drawer = (
    <List sx={{height: "100%", display: "flex", flexDirection: "column"}}>
      <ListItem
        key='customers'
        component={Link}
        href='/customers'
        disablePadding
      >
        <ListItemButton>
          <ListItemIcon>
            <AccountBoxIcon />
          </ListItemIcon>
          <ListItemText primary='Khách Hàng' />
        </ListItemButton>
      </ListItem>
      <ListItem
        key='staffs'
        component={Link}
        href='/staffs'
        disablePadding
      >
        <ListItemButton>
          <ListItemIcon>
            <Groups2Icon />
          </ListItemIcon>
          <ListItemText primary='Danh sách nhân viên' />
        </ListItemButton>
      </ListItem>
      <ListItem
        key='users'
        component={Link}
        href='/users'
        disablePadding
      >
        <ListItemButton>
          <ListItemIcon>
            <Groups2Icon />
          </ListItemIcon>
          <ListItemText primary='Danh sách khách hàng' />
        </ListItemButton>
      </ListItem>
      <ListItem
        key='logout'
        disablePadding
        sx={{ mb: 3, mt: "auto" }}
      >
        <ListItemButton onClick={() => logOut()}>
          <ListItemIcon>
            <LogoutOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary='Logout' />
        </ListItemButton>
      </ListItem>

    </List>
  );

  return (
    <>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'none', md: 'block', lg: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          bgcolor: 'primary.main',
        }}
        open={props.openMenu}
        className='vertical-menu'
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="persistent"
        sx={{
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          bgcolor: 'primary.main',
        }}
        open={props.openMenu}
        className='vertical-menu'
      >
        {drawer}
      </Drawer>
    </>
  );
}

export default ResponsiveDrawer;
