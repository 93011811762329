import Container from '@mui/material/Container';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import Skeleton from '@mui/material/Skeleton';
import { useEffect, useState } from 'react';

import Users from '../collections/users';


const Customers = (props) => {
    useEffect(() => {
        props.setPageTitle('Danh sách khách hàng');
    })

    const [users, setUsers] = useState(new Users());
    const [loading, setLoading] = useState(true);
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        if (mounted) {
            return;
        }
        setMounted(true);

        const fetchData = async () => {
            setLoading(true);
            await users.fetch();
            setLoading(false);
        };

        fetchData();
    }, [mounted, users]);
    if (loading) {
        return (
            <Skeleton
                animation="wave"
                variant="rectangular"
                height={250}
                sx={{ m: 3 }}
            />
        );
    }
    console.log(users);
    return (
        <Container sx={{ mt: 3 }}>
            <>
                <Button
                    variant="outlined"
                    size="small"
                    href="/users/new"
                    sx={{ textTransform: 'none', mb: 1 }}
                    startIcon={<AddIcon />}
                >
                    Thêm khách hàng
                </Button>
                <TableContainer component={Paper}>
                    <Table size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell align="left">Tên khách hàng</TableCell>
                                <TableCell align="left">Số điện thoại</TableCell>
                                <TableCell align="left">Địa chỉ</TableCell>
                                <TableCell align="left">Nguồn</TableCell>
                                <TableCell align="left">Ghi chú</TableCell>
                                <TableCell align="left">Thời gian tạo</TableCell>
                                <TableCell align="left">Thay đổi cuối</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {users.items.map(user => (
                                <TableRow
                                    key={user.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">{user.id}</TableCell>
                                    <TableCell align="left">{user.name}</TableCell>
                                    <TableCell align="left">{user.phone_number}</TableCell>
                                    <TableCell align="left">{user.address}</TableCell>
                                    <TableCell align="left">{user.referral_source}</TableCell>
                                    <TableCell align="left">{user.tags}</TableCell>
                                    <TableCell align="left">{user.created_at}</TableCell>
                                    <TableCell align="left">{user.updated_at}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        </Container>
    )
}

export default Customers;