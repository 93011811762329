import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { MenuItem } from '@mui/material';
import { useEffect, useState } from 'react';

import User from '../models/user';

const NewUser = (props) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const phoneRegex = /^0\d{9}$/;
    const [phoneValid, setPhoneValid] = useState(true);
    const [emailValid, setEmailValid] = useState(true);
    const [selectedStatus, setSelectedStatus] = useState('');
    const [phoneTouched, setPhoneTouched] = useState(false);
    const [emailTouched, setEmailTouched] = useState(false);

    useEffect(() => {
        props.setPageTitle('Thêm khách hàng');
    }, [props]);

    const handlePhoneBlur = (event) => {
        setPhoneTouched(true);
        const isValid = phoneRegex.test(event.target.value);
        setPhoneValid(isValid);
      };
    
      const handleEmailBlur = (event) => {
        setEmailTouched(true);
        const isValid = emailRegex.test(event.target.value);
        setEmailValid(isValid);
      };
      const handleStatusChange = (event) => {
        setSelectedStatus(event.target.value);
      };
      const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const user = new User({
          name: data.get('name'),
          phone_number: data.get('phone_number'),
          address: data.get('address'),
          email: data.get('email'),
          referral_source: data.get('source'),
          tags: data.get('tags'),
        })
        try {
          await user.save();
          alert("Thêm mới thành công!")
        }
        catch (error) {
          throw error;
        }
      }
    
      return (
        <Container component="main" maxWidth="xs" sx={{ mt: 3 }}>
          <Typography component="h1" variant="h5">
            Tạo mới khách hàng
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="name"
              label="Tên khách hàng"
              name="name"
              autoComplete="name"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="phone_number"
              label="Số điện thoại"
              name="phone_number"
              onBlur={handlePhoneBlur}
              error={phoneTouched && !phoneValid}
              helperText={phoneTouched && !phoneValid ? 'Invalid phone number. Must start with 0 and be 10 digits long.' : ''}
              autoComplete="phone_number"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="address"
              label="Địa chỉ"
              name="address"
              autoComplete="address"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              onBlur={handleEmailBlur}
              error={emailTouched && !emailValid}
              helperText={emailTouched && !emailValid ? 'Invalid email address.' : ''}
              autoComplete="email"
            />
            <TextField
              id="source"
              name="source"
              select
              value={selectedStatus}
              onChange={handleStatusChange}
              label="Nguồn"
              required
              fullWidth
              sx={{ mt: 2 }}
            >
              <MenuItem value='Bạn bè'>Bạn bè</MenuItem>
              <MenuItem value='FB Huyên Hoàng ôm bó rau'>FB Huyên Hoàng ôm bó rau</MenuItem>
              <MenuItem value='FB Huyên Hoàng vest'>FB Huyên Hoàng vest</MenuItem>
              <MenuItem value='FB Hoàng Huyên'>FB Hoàng Huyên</MenuItem>
              <MenuItem value='FB Mâys Farm (Yếnn)'>FB Mây's Farm (Yếnn)</MenuItem>
              <MenuItem value='Fanpage Tiệm Mâys Farm'>Fanpage Tiệm Mâys Farm</MenuItem>
              <MenuItem value='Hotline'>Hotline</MenuItem>
              <MenuItem value='Zalo'>Zalo</MenuItem>
              <MenuItem value='FB Huyên Thái'>FB Huyên Thái</MenuItem>
            </TextField>
            <TextField
              margin="normal"
              required
              fullWidth
              id="tags"
              label="Ghi chú"
              name="tags"
              autoComplete="tags"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Tạo mới
            </Button>
          </Box>
        </Container>
      )
    
}
export default NewUser;