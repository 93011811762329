import List from './list';
import Account from '../models/account';
import { excuseApi } from '../commons/http';

export default class Accounts extends List {
  async fetch() {
    const res = await excuseApi({
      path: '/accounts',
      method: 'GET',
    });
    if (res.status === 200) {
      this.mapData(res.data.data);
    }
  }

  mapData(data=[]) {
    for (const item of data) {
      const account = new Account();
      account.mapData(item);
      this.add(account);
    }
  }
}
