import { useEffect, useState } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';
import { jwtDecode } from "jwt-decode";

import AuthLayout from './commons/AuthLayout';

import SignIn from './components/SignIn';
import Home from './components/Home';
import VerticalMenu from './components/VerticalMenu';
import AppBar from './components/AppBar';

import NewStaff from './components/NewStaff';
import Staffs from './components/Staffs';
import Customers from './components/Users';
import NewUser from './components/NewUser';

import './App.scss';

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: '#0e2a04',
    }
  },
  typography: {
    fontFamily: [
      'Cabin',
      'Oxygen',
      'Roboto',
      'Arial',
    ].join(',')
  }
});


const App = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [controlRole, setControlRole] = useState();
  const [pageTitle, setPageTitle] = useState();
  const [openMenu, setOpenMenu] = useState();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken = jwtDecode(token);
      const userRole = decodedToken.role;
      if (userRole) {
        setControlRole(userRole);
      }
    }
    if (!token) {
      setLoggedIn(false);
    } else {
      setLoggedIn(true);
    }
  }, [setLoggedIn]);

  const toggleMenu = (newOpen) => () => {
    console.log(newOpen)
    setOpenMenu(newOpen);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      {loggedIn && <VerticalMenu setLoggedIn={setLoggedIn} openMenu={openMenu} />}
      <Container
        sx={{
          pl: { xs: 0, sm: 0, md: 31.25, lg: 31.25 },
          pr: { xs: 0, sm: 0, md: 0, lg: 0 },
          m: { xs: 0, sm: 0, md: 0, lg: 0 },
        }}
        maxWidth="100%"
      >
        {loggedIn && <AppBar pageTitle={pageTitle} toggleMenu={toggleMenu} />}
        <div onClick={toggleMenu(false)}>
          <Routes>
            <Route element={<AuthLayout />}>
              <Route path='/' element={<Home />} />
              <Route path='/staffs' element={<Staffs key="staffs" setPageTitle={setPageTitle} controlRole={controlRole} />} />
              <Route path='/staffs/new' element={<NewStaff key="new_staff" setPageTitle={setPageTitle} controlRole={controlRole} />} />
              <Route path='/users' element={<Customers key="users" setPageTitle={setPageTitle} />} />
              <Route path='/users/new' element={<NewUser key="new_user" setPageTitle={setPageTitle} />} />
            </Route>
            <Route path='/login' element={<SignIn key="login" setLoggedIn={setLoggedIn} setControlRole={setControlRole} />} />
            <Route path='*' element={<Navigate to='/' />} />
          </Routes>
        </div>
      </Container>
    </ThemeProvider>
  );
}

export default App;
