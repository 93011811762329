import { excuseApi } from '../commons/http';

class Session {
  constructor(data) {
    this.email = data.email;
    this.password = data.password;
  }

  async login() {
    const res = await excuseApi({
      path: '/sessions',
      method: 'POST',
      body: {
        email: this.email,
        password: this.password,
      }
    });
    if (res.status === 200) {
      return res.data.data;
    }
  }
}

export default Session;
