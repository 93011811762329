import { Component } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import Skeleton from '@mui/material/Skeleton';

import Accounts from '../collections/accounts';


class Staffs extends Component {
  constructor(props) {
    super(props);
    props.setPageTitle('Danh sách nhân viên');
    this.controlRole = props.controlRole;

    this.state = {
      accounts: new Accounts(),
      loading: true,
    }
    this.mounted = false;
  }

  async componentDidMount() {
    if (this.mounted) {
      return;
    }
    this.mounted = true;
    if (this.hasPermission()) {
      return;
    }
    this.setState({
      ...this.state,
      loading: true,
    });
    await this.state.accounts.fetch();
    this.setState({
      ...this.state,
      loading: false,
    });
  }

  hasPermission() {
    return this.controlRole === 'ADMIN';
  }

  render() {
    if (this.hasPermission()) {
      return (
        <Typography component="h1" variant="h5" sx={{ textAlign: "center", mt: 5 }}>
          Bạn không đủ thẩm quyền để truy cập vào tính năng này
        </Typography>
      );
    }
    if (this.state.loading) {
      return <Skeleton
        animation="wave"
        variant="rectangular"
        height={250}
        sx={{ m: 3 }}
      />;
    }
    return (
      <Container sx={{ mt: 3 }}>
        {
          <>
            <Button
              variant="outlined"
              size="small"
              href="/staffs/new"
              sx={{ textTransform: 'none', mb: 1 }}
              startIcon={<AddIcon />}
            >
              Thêm nhân viên
            </Button>
            <TableContainer component={Paper}>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell align="left">Họ tên nhân viên</TableCell>
                    {/* <TableCell align="left">Địa chỉ email</TableCell> */}
                    <TableCell align="left">Quyền truy cập</TableCell>
                    <TableCell align="left">Trạng thái</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.accounts.items.map(account => (
                    <TableRow
                      key={account.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {account.id}
                      </TableCell>
                      <TableCell align="left">{account.name}</TableCell>
                      {/* <TableCell align="left">{account.email}</TableCell> */}
                      <TableCell align="left">
                        {account.role === 'ADMIN' ? 'Quản lý' : 'Nhân viên'}
                      </TableCell>
                      <TableCell align="left">
                        {account.status === 'ACTIVE' ? 'Hoạt động' : 'Dừng hoạt động'}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        }
      </Container>
    );
  }
}

export default Staffs;
