import * as React from 'react';
import Container from '@mui/material/Container';

const Home = () => {
  return (
    <Container component="main" maxWidth="xs">
    ok
    </Container>
  );
}

export default Home;
