import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { MenuItem } from '@mui/material';
import { useEffect, useState } from 'react';

import Account from '../models/account';

const NewStaff = (props) => {
  const controlRole = props.controlRole;
  const [selectedRole, setSelectedRole] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');

  useEffect(() => {
    props.setPageTitle('Tạo mới nhân viên');
  }, [props]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const account = new Account({
      name: data.get('name'),
      email: data.get('email'),
      password: data.get('password'),
      role: data.get('role'),
      status: data.get('status')
    })
    try {
      await account.save();
    }
    catch (error) {
      throw error;
    }
  }

  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
  };

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  return (
    <>
      {controlRole === "ADMIN" ?
        <>
          <Container maxWidth="xs" sx={{ mt: 3 }}>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="name"
                label="Họ tên nhân viên"
                name="name"
                autoComplete="name"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Địa chỉ email"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Mật khẩu"
                type="password"
                id="password"
                autoComplete="current-password"
                sx={{ mt: 1 }}
              />
              <TextField
                id="role"
                name="role"
                select
                value={selectedRole}
                onChange={handleRoleChange}
                label="Quyền truy cập"
                required
                fullWidth
                sx={{ mt: 1 }}
              >
                <MenuItem value='ADMIN'>Quản lý</MenuItem>
                <MenuItem value='STAFF'>Nhân viên</MenuItem>
              </TextField>
              <TextField
                id="status"
                name="status"
                select
                value={selectedStatus}
                onChange={handleStatusChange}
                label="Trạng thái"
                required
                fullWidth
                sx={{ mt: 2 }}
              >
                <MenuItem value='ACTIVE'>Hoạt động</MenuItem>
                <MenuItem value='INACTIVE'>Dừng hoạt động</MenuItem>
              </TextField>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Tạo mới
              </Button>
            </Box>
          </Container>
        </>
        :
        <Typography component="h1" variant="h5" sx={{ textAlign: "center", mt: 5 }}>
          Bạn không đủ thẩm quyền để truy cập vào tính năng này
        </Typography>
      }
    </>
  )
}

export default NewStaff;
