import axios from 'axios';

const excuseApi = async (configs) => {
  let { path, method, body, prefix, extraHeaders } = configs;
  if (!prefix) {
    prefix = process.env.REACT_APP_API_PREFIX;
  }
  if (!extraHeaders) {
    extraHeaders = {};
  }
  const token = localStorage.getItem('token');
  if (token) {
    extraHeaders.Authorization = `Bearer ${token}`;
  }
  const options = {
    method,
    url: `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PREFIX}${path}`,
    headers: {
      'Content-Type': 'application/json',
      ...extraHeaders,
    }
  };
  if (body) {
    options.data = JSON.stringify(body);
  }
  return await axios.request(
    options
  );
}

export {
  excuseApi,
};
