import { excuseApi } from '../commons/http';


class Account {
  constructor(data={}) {
    this.email = data.email;
    this.name = data.name;
    this.password = data.password;
    this.role = data.role;
    this.status = data.status;
  }

  mapData(data) {
    this.id = data.id;
    this.email = data.email;
    this.name = data.name;
    this.role = data.role;
    this.status = data.status;
  }

  async save() {
    const res = await excuseApi({
      path: '/accounts',
      method: 'POST',
      body: {
        name: this.name,
        email: this.email,
        password: this.password,
        role: this.role,
        status: this.status
      }
    });
    if (res.status === 200) {
      return res.data.data;
    }
  }
}

export default Account;
