import { Navigate, Outlet } from 'react-router-dom';

const AuthLayout = () => {
  const token = localStorage.getItem('token');
  if (token) {
    return <Outlet />;
  }
  return <Navigate to='/login' replace />
}

export default AuthLayout;
